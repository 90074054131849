import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useState } from "react";
import React from 'react';

import whatsapp from "../assets/img/whatsapp.png"
import logo from "../assets/img/Logo no bg.png"


const LandingPage = () => {
    const handleWhatsAppClick = (phone, message) => {
        const encodedText = encodeURIComponent(message);
        const url = `https://wa.me/${phone}?text=${encodedText}`;
        window.location.href = url;
    };

    return(
        <div className="home-container">
            <div className="home-banner-container">
                <div className="home-text-section">
                    <img src={logo} alt="Logo" className="logo" />
                    <h1 className="primary-heading">
                        Haii, Kami Adalah Creative Digital Agency</h1>
                    <p className="primary-text">
                        Butuh bantuan untuk bikin konten Reels atau Tiktok?
                    </p>
                    <p className="primary-text">
                        Atau pengen punya website untuk bisnis kamu?
                    </p>
                    <div className="button-container">
                        <button className="secondary-button" onClick={() => handleWhatsAppClick('6281288885896', 'Hai Rumoos, Saya ingin konsultasi mengenai video konten')}>
                            <img src={whatsapp} alt="WhatsApp" className="button-icon" />
                            Konsultasi Konten
                        </button>
                        <button className="secondary-button" onClick={() => handleWhatsAppClick('6285275530651', 'Hai Rumoos, Saya ingin membuat website')}>
                            <img src={whatsapp} alt="WhatsApp" className="button-icon" />
                            Konsultasi Website
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default LandingPage;