import logo from './assets/img/Logo no bg.png';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import './App.css';

import LandingPage from './components/landingPage';

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
